import {
  Container,
  Grid,
  Toolbar,
  Typography,
  TextField,
  Select,
  MenuItem,
  LinearProgress,
  makeStyles,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputLabel,
  FormControl
} from "@material-ui/core";
import { API } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import ConfirmModal from './ModalConfirm';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const apiName = `${process.env.REACT_APP_ENV}-instaladores`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  linearProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
  },
  table: {},
}));

interface IEmpresa {
  id: number;
  nombre: string;
  correo_bloqueo: string;
}
interface IUsuarioEmpresa {
  id: number;
  nombre: string;
  apellido:string;
  canal_id:string;
  email: string;
  telefono:string
}

interface ISolicitante {
  userEmpresaId:string;
  nombre: string;
  apellido: string;
  telefono: string;
  email: string;
  empresa?: IEmpresa;
}

export default () => {
  const solicitanteInitialState = {
    userEmpresaId:"",
    nombre:"",
    apellido:"",
    telefono: "",
    email: "",
    empresa:{
      id:0,
      nombre:'',
      correo_bloqueo:''
    }
  };

  const classes = useStyles();
  const [isAuth, setAuth] = useState(false);
  const [securityCode, setSecurityCode] = useState("");
  const [error, setError] = useState({
    codigo: "",
    placa: "",
    empresa: "",
    persona: "",
    telefono: "",
    email: "",
  });
  const [loading, setLoading] = useState("");
  const [vehiculo, setVehiculo] = useState({
    placa: "",
    chasis: "",
    modelo: "",
    marca_dispositivo: "",
    modelo_dispositivo: "",
    bloqueado: 0,
    id: "",
    vehiculo_id:'',
    bloqueoMotor: 0,
    solicitudId: "",
    sensorBloqueado: false,
    fecha_ultima_conexion: "",
    fecha_expiracion: "",
  });
  const [cliente, setCliente] = useState({
    id: "",
    ciruc: "",
    nombres: "",
    apellidos: "",
    email: "",
    emnifyId: "",
  });


  const [showModalConfirm, setShowModalConfirm] = useState<"bloqueo" | "desbloqueo" | "none">("none");
  const [solicitante, setSolicitante] = useState<ISolicitante>(
    solicitanteInitialState
  );
  const [usuariosEmpresa, setUsuariosEmpresa] = useState<IUsuarioEmpresa[]>([]);
  const [historial, setHistorial] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [vehiculoKey, setVehiculoKey] = useState("");
  const [tieneChasis, setTieneChasis] = useState(false);
  //Estado para manejar la edición del chasis
  const [editarChasis, setEditarChasis] = useState(false);
  const [guardandoChasis, setGuardandoChasis] = useState(false);
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);

  const onAuth = () => {
    if (securityCode === "Qc94#kEP&JCE") {
    // if (securityCode === "") {
      setAuth(true);
    } else {
      setError({ ...error, codigo: "Código Incorrecto" });
    }
  };

  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const result = await API.get(apiName, "/empresas?bloquea=si", {});
        console.log(result)
        setEmpresas(result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchEmpresas();
  }, [solicitante.empresa]);


  useEffect(()=>{
    setSolicitante({
      ...solicitante,
      telefono :'',
      email:'',userEmpresaId:'',
      apellido:'',
      nombre:'',      
    })
    const fetchUsuariosEmpresas = async () => {
      try {console.log()
        const response = await API.get(apiName, `/empresas?id=${solicitante.empresa!.id}`, {});
        if(response.length>0){
          setSolicitante({
            ...solicitante,
            userEmpresaId: response[0].id,
            apellido:response[0].apellido,
            email:response[0].email,
            nombre:response[0].nombre,
            telefono:response[0].telefono
          })
        }
        console.log(response)
        setUsuariosEmpresa(response);
      } catch (error) {
        console.error(error);
      }
    };
    if(solicitante.empresa && solicitante.empresa.id){
      console.log(`empresa id= ${solicitante.empresa.id}`)
      fetchUsuariosEmpresas();
    }
  },[ solicitante.empresa])

  useEffect(() => {
    console.log("Solicitante", solicitante);
    console.log(loading !== "" || vehiculo.modelo === "" || Object.values(solicitante).some(
      (v) => v === "" || v === null || v === undefined))
  }, [solicitante]);

  const guardarChasis = async () => {
    setGuardandoChasis(true);
    console.log(vehiculo);

    try {
      const resultGuardarChasis = await API.put(apiName, `/vehiculos`, {
        body: {
          vehiculo,
        },
      });
      console.log(resultGuardarChasis);
      alert("El chasis se guardo con exito");
    } catch (error) {
      alert("El chasis no se pudo guardar");
    }
    setGuardandoChasis(false);
    //Acción para habilitar/inhabilitar edición de chasis
    setEditarChasis(!editarChasis);
  };

  const onBloquear = async () => {
    try {
      setLoading("bloquear");
      const resultBloqueo = await API.post(apiName, `/bloqueos`, {
        body: {
          cliente: {
            ...cliente,
            nombre: `${cliente.nombres} ${cliente.apellidos}`,
          },
          vehiculo,
          solicitante,
        },
      });
      console.log(resultBloqueo);
      alert("El vehículo ha sido bloqueado");
      setVehiculo({
        placa: "",
        chasis: "",
        modelo: "",
        marca_dispositivo: "",
        modelo_dispositivo: "",
        bloqueado: 0,
        id: "",
        vehiculo_id:'',
        bloqueoMotor: 0,
        solicitudId: "",
        sensorBloqueado: false,
        fecha_ultima_conexion: "",
        fecha_expiracion: "",
      });
      setCliente({
        id: "",
        ciruc: "",
        nombres: "",
        apellidos: "",
        email: "",
        emnifyId: "",
      });
      setSolicitante(solicitanteInitialState);
      setLoading("");
    } catch (error) {
      alert(error);
      setLoading("");
    }
    finally {
      setShowModalConfirm("none")
    }
  };

  const onAgendar = async () => {
    console.log("SOlictiante values", solicitante);
    console.log(Object.values(solicitante));
    if (Object.values(solicitante).every((v) => v !== "" && v !== null)) {
      try {
        setLoading("bloquear");
        
        const resultBloqueo = await API.patch(apiName, `/bloqueos`, {
          body: {
            cliente: {
              ...cliente,
              nombre: `${cliente.nombres} ${cliente.apellidos}`,
            },
            vehiculo,
            solicitante,
          },
        });
        console.log(resultBloqueo);
        console.log(`vehiculo: ${JSON.stringify(vehiculo)}`)
        console.log(`solicitante: ${JSON.stringify(solicitante)}`)
        alert("El vehículo se bloqueará a las 3 AM");
        setVehiculo({
          placa: "",
          chasis: "",
          modelo: "",
          marca_dispositivo: "",
          modelo_dispositivo: "",
          bloqueado: 0,
          id: "",
          vehiculo_id:'',
          bloqueoMotor: 0,
          solicitudId: "",
          sensorBloqueado: false,
          fecha_ultima_conexion: "",
          fecha_expiracion: "",
        });
        setCliente({
          id: "",
          ciruc: "",
          nombres: "",
          apellidos: "",
          email: "",
          emnifyId: "",
        });
        setSolicitante(solicitanteInitialState);
        setLoading("");
      } catch (error) {
        alert(error);
        setLoading("");
      }
    } else {
      alert("La información del solicitante es obligatoria*");
    }
  };

  const onDesbloquear = async () => {
    try {
      setLoading("bloquear");
      const resultBloqueo = await API.put(apiName, `/bloqueos`, {
        body: {
          cliente: {
            ...cliente,
            nombre: `${cliente.nombres} ${cliente.apellidos}`,
          },
          vehiculo,
          solicitante,
        },
      });
      console.log(resultBloqueo);
      alert("El vehículo ha sido desbloqueado");
      setVehiculo({
        placa: "",
        modelo: "",
        chasis: "",
        marca_dispositivo: "",
        modelo_dispositivo: "",
        bloqueado: 0,
        id: "",
        vehiculo_id:'',
        bloqueoMotor: 0,
        solicitudId: "",
        sensorBloqueado: false,
        fecha_ultima_conexion: "",
        fecha_expiracion: "",
      });
      setCliente({
        id: "",
        ciruc: "",
        nombres: "",
        apellidos: "",
        email: "",
        emnifyId: "",
      });
      setSolicitante(solicitanteInitialState);
      setLoading("");
    } catch (error) {
      alert(error);
      setLoading("");
    }
    finally {
      setShowModalConfirm("none")
    }
  };

  const onBuscar = async () => {
    //Deshabilitar edición de chasis mientras se busca un vehículo
    setEditarChasis(false);
    if (vehiculoKey === "") {
      setError({ ...error, placa: "Ingrese el número de placa" });
    } else {
      console.log("Run...")
      try {
        setLoading("buscar");
        const resultVehiculo = await API.get(
          apiName,
          `/bloqueos/${vehiculoKey}`,
          {}
        );
        setLoading("");

        console.log(`resultado buscar vehiculo :${JSON.stringify(resultVehiculo)}`)
        if (resultVehiculo) {
          console.log(`result vehiculo ${JSON.stringify(resultVehiculo)}`)
          setTieneChasis(resultVehiculo.chasis ? true : false);
          setVehiculo({
            id: resultVehiculo.id,
            vehiculo_id:resultVehiculo.vehiculo_id,
            placa: resultVehiculo.placa,
            chasis: resultVehiculo.chasis,
            modelo: resultVehiculo.vehiculo,
            marca_dispositivo: resultVehiculo.marca_dispositivo,
            modelo_dispositivo: resultVehiculo.modelo_dispositivo,
            bloqueado: resultVehiculo.bloqueado,
            bloqueoMotor: resultVehiculo.bloqueo_motor,
            solicitudId: resultVehiculo.solicitud_id,
            sensorBloqueado: resultVehiculo.sensorBloqueado,
            fecha_ultima_conexion: resultVehiculo.fecha_ultima_conexion,
            fecha_expiracion: resultVehiculo.fecha_expiracion,
          });
          setCliente({
            id: resultVehiculo.usuarioId,
            ciruc: resultVehiculo.ciruc,
            nombres: resultVehiculo.nombres,
            apellidos: resultVehiculo.apellidos,
            email: resultVehiculo.correo,
            emnifyId: resultVehiculo.emnifyId,
          });
          
          setSolicitante({
            userEmpresaId:resultVehiculo.user_canal_id,
          nombre:resultVehiculo.nombre_solicitante,
          apellido:resultVehiculo.apellido_solicitante,
          telefono: resultVehiculo.telefono_solicitante,
          email: resultVehiculo.email_solicitante,
          empresa:{
            id:resultVehiculo.canal_id,
            nombre:resultVehiculo.empresa_bloqueo,
            correo_bloqueo:resultVehiculo.correo_bloqueo
          }
          });
          
          setHistorial(resultVehiculo.historial);
          setEventos(resultVehiculo.eventos);
        } else {
          setError({ ...error, placa: "Vehículo no encontrado" });
        }
      } catch (error) {
        setLoading("");
      }
    }
  };


  const validaFechaExpiracion = (fechaExpiracionString: any, fechaUltimaConexion: any) => {
    if (!fechaExpiracionString) {
      return dayjs(fechaUltimaConexion).tz("America/Guayaquil")
        .format("DD-MM-YYYY h:mm:ss a")
    }
    let parsedFechaExpiracion = dayjs(fechaExpiracionString)
    if (dayjs().diff(parsedFechaExpiracion) > 0) {
      return "Expirado";
    } else {
      return dayjs(fechaUltimaConexion).tz("America/Guayaquil")
        .format("DD-MM-YYYY h:mm:ss a")
    }
  }

  return (
    <div>
      <Toolbar variant="regular" style={{ justifyContent: "center" }}>
        <Typography variant="h5" color="inherit">
          BLOQUEO DE DISPOSITIVOS
        </Typography>
      </Toolbar>
      {isAuth && (
        <form className={classes.root} noValidate autoComplete="off">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                item
                sm={8}
              >
                <Container maxWidth={"sm"}>
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    label={"Placa / Chasis o ID del Vehículo"}
                    placeholder="Ingrese un identificador del vehículo"
                    value={vehiculoKey || ""}
                    onChange={(e) => {
                      setVehiculoKey(e.target.value);
                      setError({ ...error, placa: "" });
                    }}
                    onKeyPress={(e) => e.key === "Enter" && onBuscar()}
                    error={error.placa === "" ? false : true}
                    helperText={error.placa}
                  />
                </Container>

                {vehiculo.id !== "" && vehiculo.bloqueoMotor === 0 && (
                  <Container maxWidth={"sm"}>
                    <label style={{ color: "orangered", fontStyle: "italic" }}>
                      * No se ha instalado el bloqueo de motor en este vehículo
                    </label>
                  </Container>
                )}
                {loading !== "device" && (
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => onBuscar()}
                    disabled={loading !== "" ? true : false}
                  >
                    Buscar Vehículo
                  </Button>
                )}

                {loading === "buscar" && (
                  <Container maxWidth={"sm"}>
                    <div className={classes.linearProgress}>
                      <LinearProgress />
                    </div>
                  </Container>
                )}
                {vehiculo.id && (
                  <>
                    {/* Se agrega campo id del vehículo */}
                    <Container maxWidth={"sm"}>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        label={"Id"}
                        disabled={true}
                        value={vehiculo.id}
                        variant="outlined"
                      />
                    </Container>
                    {/* Se agrega campo placa */}
                    <Container maxWidth={"sm"}>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        label={"Placa"}
                        disabled={true}
                        value={vehiculo.placa || "N/A"}
                        variant="outlined"
                      />
                    </Container>

                    <Container maxWidth={"sm"}>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        label={"Ultima conexion"}
                        disabled={true}
                        value={validaFechaExpiracion(vehiculo.fecha_expiracion, vehiculo.fecha_ultima_conexion) || "N/A"}
                        variant="outlined"
                      />
                    </Container>

                    <Container maxWidth={"sm"}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "103%",
                        }}
                      >
                        {/* Campo de entrada para chasis, habilitado solo si no hay chasis registrado */}
                        <TextField
                          style={{ width: tieneChasis ? "100%" : "75%" }}
                          label={"Chasis"}
                          disabled={!editarChasis}
                          value={
                            vehiculo.chasis && vehiculo.chasis !== ""
                              ? vehiculo.chasis
                              : editarChasis
                                ? ""
                                : "N/A"
                          }
                          onChange={(e) =>
                            setVehiculo({ ...vehiculo, chasis: e.target.value })
                          }
                          variant="outlined"
                        />
                        {/* Botón para editar chasis, visible solo si no tiene chasis */}
                        {!tieneChasis && (
                          <Button
                            style={{ display: "block", margin: "auto" }}
                            variant={"contained"}
                            color={"primary"}
                            onClick={() =>
                              !editarChasis
                                ? setEditarChasis(!editarChasis)
                                : guardarChasis()
                            }
                            disabled={guardandoChasis}
                          >
                            {editarChasis ? "Guardar" : "Editar"}
                          </Button>
                        )}
                      </div>
                    </Container>
                  </>
                )}
                <Container maxWidth={"sm"}>
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    label={"Vehículo"}
                    disabled={true}
                    value={vehiculo.modelo}
                    variant="outlined"
                  />
                </Container>
                <Container maxWidth={"sm"}>
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    label={"Cliente"}
                    disabled={true}
                    value={cliente.nombres + " " + cliente.apellidos}
                    variant="outlined"
                  />
                </Container>
                <Container maxWidth={"sm"}>
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    label={"Cédula"}
                    disabled={true}
                    value={cliente.ciruc}
                    variant="outlined"
                  />
                </Container>
                {vehiculo.bloqueado === 0 && (
                  <div style={{ width: "100%" }}>
                    <Container maxWidth={"sm"} >


                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Empresa Solicitante *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          style={{
                            width: "100%",
                          }}
                          value={solicitante.empresa!.id || "" }
                          onChange={(e, c: any) => {
                            let empresaSelected = empresas.find(empresa => empresa.id === c.props.value)
                            setSolicitante({
                              ...solicitante,
                              empresa:{
                                id:empresaSelected!.id,
                                correo_bloqueo:empresaSelected!.correo_bloqueo,
                                nombre:empresaSelected!.nombre
                              }
                            });
                            setError({
                              ...error,
                              empresa:
                                e.target.value === ""
                                  ? "Ingrese el nombre de la empresa"
                                  : "",
                            });
                          }}
                          error={error.empresa === "" ? false : true}
                        >
                          {empresas.map((empresa) => (
                            <MenuItem key={empresa.id} value={empresa.id}>
                              {empresa.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Container>

                  
                      <Container maxWidth={"sm"} style={{marginTop:'10px'}}>
                      
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Persona Solicitante *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          style={{
                            width: "100%",
                          }}
                          value={
                            solicitante.userEmpresaId
                          }
                          onChange={(e, c: any) => {
                            console.log(c)
                            let usuarioEmpresaSelected= usuariosEmpresa.find((usuario)=>usuario.id===c.props.value)
                            setSolicitante({
                              ...solicitante,
                              userEmpresaId:`${usuarioEmpresaSelected!.id}`,
                              apellido:usuarioEmpresaSelected!.apellido,
                              nombre:usuarioEmpresaSelected!.nombre,
                              telefono:usuarioEmpresaSelected!.telefono,
                              email:usuarioEmpresaSelected!.email
                            });
                            /*setError({
                              
                            });*/
                          }}
                          
                        >
                          {usuariosEmpresa.map(usuarioEmpresa => (
                            <MenuItem key={usuarioEmpresa.id} value={usuarioEmpresa.id}>
                              {`${usuarioEmpresa.nombre} ${usuarioEmpresa.apellido}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Container>
                      
                    <Container maxWidth={"sm"}>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        required
                        label="Teléfono del Solicitante"
                        value={solicitante.telefono || ""}
                        onChange={(e) => {
                          setSolicitante({
                            ...solicitante,
                            telefono: e.target.value,
                          });
                          setError({
                            ...error,
                            telefono:
                              e.target.value === ""
                                ? "Ingrese el teléfono de la persona solicitante"
                                : "",
                          });
                        }}
                        error={error.telefono === "" ? false : true}
                        helperText={error.telefono}
                      />
                    </Container>
                    <Container maxWidth={"sm"}>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        required
                        label="Email del Solicitante"
                        value={solicitante.email || ""}
                        onChange={(e) => {
                          setSolicitante({
                            ...solicitante,
                            email: e.target.value,
                          });
                          setError({
                            ...error,
                            email:
                              e.target.value === ""
                                ? "Ingrese el email de la persona solicitante"
                                : "",
                          });
                        }}
                        error={error.email === "" ? false : true}
                        helperText={error.email}
                      />
                    </Container>
                  </div>
                )}
                <Container maxWidth="sm">
                  {loading !== "bloquear" && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        disabled={loading !== "" || vehiculo.modelo === "" /*|| Object.values(solicitante).some(
                          (v) => v === "" || v === null || v === undefined
                        ) ? true : false*/}
                        onClick={() => {
                          if (vehiculo.bloqueado) {
                            // onDesbloquear();
                            setShowModalConfirm("desbloqueo");
                          } else {
                            // onBloquear();
                            setShowModalConfirm("bloqueo")
                          }
                        }}
                      >
                        {vehiculo.bloqueado === 0 ? "Bloquear" : "Desbloquear"}{" "}
                        Vehículo
                      </Button>
                      {vehiculo.bloqueado === 0 && (
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          disabled={loading !== "" || vehiculo.modelo === "" ? true : false}
                          onClick={() => {
                            onAgendar();
                          }}
                        >
                          Agendar Bloqueo
                        </Button>
                      )}
                    </Grid>
                  )}
                  {loading === "bloquear" && (
                    <div className={classes.linearProgress}>
                      <LinearProgress />
                    </div>
                  )}
                </Container>
              </Grid>
              <Grid item sm={4}>
                {vehiculo.sensorBloqueado && (
                  <TextField
                    value={`Dispositivo Bloqueado`}
                    disabled={true}
                    variant={"filled"}
                  />
                )}
                <TextField
                  value={"Alertas Salida de Zona_Bloqueo"}
                  disabled={true}
                  variant={"standard"}
                />
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: 450, maxWidth: 300 }}
                >
                  <Table className={classes.table} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Fecha</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {eventos.map((item: any) => (
                        <TableRow key={item.id}>
                          <TableCell align="left">
                            {dayjs(item.time)
                              .tz("America/Guayaquil")
                              .format("DD-MM-YYYY h:mm:ss a")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Container>
                  <TextField
                    value={`Total: ${eventos.length}`}
                    disabled={true}
                    variant={"standard"}
                  />
                </Container>
              </Grid>
            </Grid>
            <Grid>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Fecha creación</TableCell>
                      <TableCell align="left">Fecha actualización</TableCell>
                      <TableCell align="left">Empresa</TableCell>
                      <TableCell align="left">Persona</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Operacion</TableCell>
                      <TableCell align="left">Tipo</TableCell>
                      <TableCell align="left">Estado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historial.map((item: any) => (
                      <TableRow key={item.id}>
                        <TableCell align="left">
                          {dayjs(item.creado)
                            .tz("America/Guayaquil")
                            .format("DD-MM-YYYY H:mm:ss")}
                        </TableCell>
                        <TableCell align="left">
                          {dayjs(item.actualizado)
                            .tz("America/Guayaquil")
                            .format("DD-MM-YYYY H:mm:ss")}
                        </TableCell>
                        <TableCell align="left">
                          {item.canal}
                        </TableCell>
                        <TableCell align="left">
                          {`${item.nombre_solicitante} ${item.apellido_solicitante}`}
                        </TableCell>
                        <TableCell align="left">
                          {item.email}
                        </TableCell>
                        <TableCell align="left">{item.operacion}</TableCell>
                        <TableCell align="left">
                          {item.tipo_ejecucion}
                        </TableCell>
                        <TableCell align="left">
                          {item.estado}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <div className="clipping-container">
            {showModalConfirm === "bloqueo" && createPortal(
              <ConfirmModal onClose={() => setShowModalConfirm("none")} onAceptar={onBloquear} operacion="bloqueo" />,
              document.body
            )}
            {showModalConfirm === "desbloqueo" && createPortal(
              <ConfirmModal onClose={() => setShowModalConfirm("none")} onAceptar={onDesbloquear} operacion="desbloqueo" />,
              document.body
            )}
          </div>
        </form>
      )}
      {!isAuth && (
        <div>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Container maxWidth={"sm"}>
              <TextField
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                label={"Código"}
                placeholder="Ingrese el código de acceso"
                value={securityCode}
                onChange={(e) => {
                  setSecurityCode(e.target.value);
                  setError({ ...error, codigo: "" });
                }}
                onKeyPress={(e) => e.key === "Enter" && onAuth()}
                error={error.codigo === "" ? false : true}
                helperText={error.codigo}
                type={"password"}
              />
            </Container>
            <Button
              onClick={() => onAuth()}
              variant={"contained"}
              color={"primary"}
            >
              Acceder
            </Button>
          </Grid>
        </div>
      )}
    </div>
  );
};
