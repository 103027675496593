import React, { useState } from "react";
import Axios from "axios";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
// import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from "@material-ui/core/LinearProgress";
import { API } from "aws-amplify";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import { CircularProgress, FormLabel } from '@material-ui/core';
import { IPresenterProps } from "./types";
import StatusSMS from "../statusSMS";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },

  linearProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default (props: IPresenterProps) => {
  const classes = useStyles();
  const [finalizarActive, setFinalizarActive] = useState(false);
  return (
    <div>
      <Toolbar variant="regular" style={{ justifyContent: "center" }}>
        <Typography variant="h5" color="inherit">
          REVISIÓN DE DISPOSITIVOS
        </Typography>
      </Toolbar>
      {props.vehiculo && (
        <Grid>
          <Grid container justifyContent={"center"}>
            <Grid className={classes.root} item sm>
              <Container>
                <TextField
                  style={{
                    width: "100%",
                  }}
                  required
                  label="Número de instalación"
                  value={props.vehiculo.solicitud_id}
                  disabled
                />
                <TextField
                  style={{
                    width: "100%",
                  }}
                  label="Vehículo"
                  disabled
                  value={`${props.vehiculo.marca} ${props.vehiculo.modelo} ${props.vehiculo.anio}`}
                  variant="outlined"
                />
              </Container>

              <Container>
                <TextField
                  required
                  style={{
                    width: "100%",
                    marginTop: "15px",
                  }}
                  label="Nombre del técnico"
                  value={props.tecnico}
                  onChange={(e) => props.setTecnico(e.target.value)}
                  variant="outlined"
                  error={props.errorTecnico ? true : false}
                  helperText={props.errorTecnico}
                />
                <span
                  style={{
                    color: "red",
                    marginTop: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {props.errorConfigurar}
                </span>
              </Container>

              <Container>
                <TextField
                  style={{
                    width: "100%",
                  }}
                  label={"Número de serie"}
                  placeholder="Ingrese el número de serie"
                  value={props.dispositivo.serial}
                  onChange={(e) => props.setNumeroSerie(e.target.value)}
                  disabled={!props.cambiarDispositivo}
                  onKeyPress={(e) =>
                    e.key === "Enter" && props.onObtenerDispositivo()
                  }
                  error={props.errorObtnerDispositivo ? true : false}
                  helperText={props.errorObtnerDispositivo}
                />
                {props.cambiarDispositivo && props.loading !== "device" && (
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => props.onObtenerDispositivo()}
                    disabled={props.loading !== "" ? true : false}
                  >
                    Buscar
                  </Button>
                )}
                {props.cambiarDispositivo && props.loading === "device" && (
                  <div className={classes.linearProgress}>
                    <LinearProgress />
                  </div>
                )}

                {props.cambiarDispositivo && (
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    label={"SIM ID"}
                    disabled={true}
                    value={props.dispositivo.simId}
                    variant="outlined"
                  />
                )}
                <TextField
                  required
                  style={{
                    width: "100%",
                  }}
                  label="Placa del vehículo"
                  disabled
                  value={props.vehiculo.placa || ""}
                  onChange={(e) => {
                    const withNoSpaces = e.target.value.trim();
                    props.setPlaca(withNoSpaces);
                  }}
                />
                <TextField
                  style={{
                    width: "100%",
                  }}
                  label="Número de Chasis"
                  disabled
                  value={props.vehiculo.chasis || ""}
                  onChange={(e) => {
                    const withNoSpaces = e.target.value.replace(/\s+/g, "");
                    props.setChasis(withNoSpaces);
                  }}
                />
              </Container>
              <Container>
                {props.cambiarDispositivo ? (
                  <>
                    {props.loading !== "setup" && (
                      <>
                        <Button
                          onClick={() => props.setCambiarDispositivo(false)}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color={"primary"}
                          variant={"contained"}
                          onClick={props.onConfigurarDispositivo}
                          disabled={
                            props.dispositivo.simId === "" ? true : false
                          }
                        >
                          Cambiar
                        </Button>
                      </>
                    )}

                    {props.cambiarDispositivo && props.loading === "setup" && (
                      <div className={classes.linearProgress}>
                        <LinearProgress />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        props.setCambiarDispositivo(true);
                      }}
                      color={"primary"}
                      variant={"contained"}
                    >
                      Cambiar equipo
                    </Button>
                  </>
                )}
              </Container>

              <Container>
                <TextField
                  style={{
                    width: "100%",
                  }}
                  label="Notas (Opcional)"
                  value={props.notas}
                  onChange={(e) => props.setNotas(e.target.value)}
                  multiline
                  variant="outlined"
                />
                <span
                  style={{
                    color: "red",
                    marginTop: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {props.errorConfigurar}
                </span>
                {props.loading !== "setup" && (
                  <Button
                    onClick={() => {
                      setFinalizarActive(true);
                      props.onFinalizar();
                    }}
                    variant={"contained"}
                    color={"primary"}
                  /* disabled={props.tecnico === "" || finalizarActive}*/
                  >
                    Finalizar
                  </Button>
                )}
              </Container>
            </Grid>
            <Grid className={classes.root} item sm>
              {!props.cambiarDispositivo && (
                <>
                  {!props.cambioRealizado && (
                    <div style={{ marginTop: 20, marginBottom: 10 }}>
                      <Container>
                        <span>Equipo listo para realizar pruebas.</span>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="center"
                          style={{
                            marginTop: 10,
                            height: 100,
                          }}
                        >
                          <Button
                            variant={"contained"}
                            onClick={async () => {
                              // console.log("Device", props.dispositivo);
                              // console.log("emnfiy auth", props.emnifyAuthToken);
                              // console.log("comandos", props.comandos);
                              try {
                                const result = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                  body: {
                                    operation: "send_command",
                                    device_id: props.dispositivo.id,
                                    emnify_id: props.dispositivo.emnifyId,
                                    marcaSim:props.dispositivo.marcaSim,
                                    user_api_hash: `this.props.authUser.user_api_hash`,
                                    emnfiy_auth_token: props.dispositivo.emnifyToken,
                                    orion_token: props.dispositivo.orionToken,
                                    flolive_token:props.dispositivo.floliveToken,
                                    commands: {
                                      sms: props.dispositivo.comandos.puertas,
                                      gprs: props.dispositivo.comandos.puertas_gprs
                                    },
                                    description: "abrir puertas",
                                  }
                                });
                                if (result.message.includes("created")) {
                                  alert('El comando de apertura de puertas ha sido enviado.');
                                } else if (result.message.includes("success")) {
                                  alert('Puertas abiertas.');
                                }
                              } catch (error) {
                                console.log('AxiosError', error);
                                alert(`Error al enviar el comando: ${error}`)
                              }
                            }}
                          >
                            Abrir Puertas
                          </Button>
                          <Button
                            variant={"contained"}
                            onClick={async () => {
                              // console.log("Device", props.dispositivo);
                              // console.log("emnfiy auth", props.emnifyAuthToken);
                              // console.log("comandos", props.comandos);
                              if (props.dispositivo.simId === "") {
                                alert(
                                  "Error: Primero debe buscar un Dispositivo"
                                );
                                return;
                              }
                              try {
                                const result = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                  body: {
                                    operation: "send_command",
                                    device_id: props.dispositivo.id,
                                    emnify_id: props.dispositivo.emnifyId,
                                    user_api_hash: `this.props.authUser.user_api_hash`,
                                    emnfiy_auth_token: props.dispositivo.emnifyToken,
                                    orion_token: props.dispositivo.orionToken,
                                    marcaSim:props.dispositivo.marcaSim,
                                    flolive_token:props.dispositivo.floliveToken,
                                    commands: {
                                      sms: props.dispositivo.comandos.bloqueo,
                                      gprs: props.dispositivo.comandos.bloqueo_gprs
                                    },
                                    description: "bloquear motor"
                                  }
                                });
                                console.log(result);
                                if (result.message.includes("created")) {
                                  alert("El comando de bloqueo ha sido enviado.\nEste comando se ejecutará una vez que el dispositivo reciba el comando. Si el comando no se recibe hasta dentro de 1 hora la acción será cancelada. Te recomendamos mover el vehículo un poco para optimizar la recepción del comando.");
                                } else if (result.message.includes("success")) {
                                  alert('El motor ha sido bloqueado.');
                                } else {
                                  const arrayMessage = result.message.split(" ");
                                  const time_left = 60 - Number(arrayMessage[1]);
                                  if (window.confirm(`Operación Pendiente\nYa has enviado este comando recientemente. Este comando tratará de enviarse por ${time_left} minutos más. Si deseas esperar a que se envie el comando presiona Aceptar, o presiona Cancelar para eliminar el comando en cola.`)) {

                                  } else {
                                    try {
                                      const resultDelete = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                        body: {
                                          operation: "delete_command",
                                          sms: result.sms,
                                          emnfiy_auth_token: props.dispositivo.emnifyToken,
                                          orion_token: props.dispositivo.orionToken,
                                          flolive_token:props.dispositivo.floliveToken,
                                          marcaSim:props.dispositivo.marcaSim,
                                          description: "eliminar comando"
                                        }
                                      });
                                      alert("El comando ha sido eliminado")
                                    } catch (e) {
                                      alert(`Error, ${e}`)
                                    }
                                  }
                                }
                              } catch (error) {
                                alert(`Error al enviar el comando\n, ${error} Por favor intentelo mas tarde.`);
                              }
                            }}
                          >
                            Bloquear Motor
                          </Button>
                          <Button
                            variant={"contained"}
                            onClick={async () => {
                              if (
                                props.dispositivo &&
                                props.dispositivo.simId === ""
                              ) {
                                alert(
                                  "Error: Primero debe buscar un Dispositivo"
                                );
                                return;
                              }
                              try {
                                const result = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                  body: {
                                    operation: "send_command",
                                    device_id: props.dispositivo.id,
                                    emnify_id: props.dispositivo.emnifyId,
                                    user_api_hash: `this.props.authUser.user_api_hash`,
                                    emnfiy_auth_token: props.dispositivo.emnifyToken,
                                    orion_token: props.dispositivo.orionToken,
                                    flolive_token:props.dispositivo.floliveToken,
                                    marcaSim:props.dispositivo.marcaSim,
                                    commands: {
                                      sms: props.dispositivo.comandos.desbloqueo,
                                      gprs: props.dispositivo.comandos.desbloqueo_gprs
                                    },
                                    description: "desbloquear motor"
                                  }
                                });
                                console.log(result);
                                if (result.message.includes("created")) {
                                  alert("El comando de desbloqueo ha sido enviado.\nEste comando se ejecutará una vez que el dispositivo reciba el comando. Si el comando no se recibe hasta dentro de 1 hora la acción será cancelada. Te recomendamos mover el vehículo un poco para optimizar la recepción del comando.");
                                } else if (result.message.includes("success")) {
                                  alert('El motor ha sido desbloqueado.');
                                } else {
                                  const arrayMessage = result.message.split(" ");
                                  const time_left = 60 - Number(arrayMessage[1]);
                                  if (window.confirm(`Operación Pendiente\nYa has enviado este comando recientemente. Este comando tratará de enviarse por ${time_left} minutos más. Si deseas esperar a que se envie el comando presiona Aceptar, o presiona Cancelar para eliminar el comando en cola.`)) {

                                  } else {
                                    try {
                                      const resultDelete = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                        body: {
                                          operation: "delete_command",
                                          sms: result.sms,
                                          emnfiy_auth_token: props.dispositivo.emnifyToken,
                                          orion_token: props.dispositivo.orionToken,
                                          flolive_token:props.dispositivo.floliveToken,
                                          description: "eliminar comando",
                                          marcaSim:props.dispositivo.marcaSim,
                                        }
                                      });
                                      alert("El comando ha sido eliminado")
                                    } catch (e) {
                                      alert(`Error, ${e}`)
                                    }
                                  }
                                }
                              } catch (error) {
                                alert(`Error al enviar el comando\n, ${error} Por favor intentelo mas tarde.`);
                              }
                            }}
                          >
                            Quitar Bloqueos
                          </Button>
                          <Button
                            variant={"contained"}
                            onClick={() => {
                              props.onEnviarComandoGPRS();
                            }}
                          >
                            Conexión Servidor
                          </Button>
                        </Grid>
                      </Container>
                    </div>
                  )}
                  {props.cambioRealizado && (
                    <StatusSMS
                      dispositivo={props.dispositivo}
                      vehiculo={props.vehiculo}
                      onEnviarComandoGPRS={props.onEnviarComandoGPRS}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {!props.vehiculo && (
        <Container maxWidth={"sm"}>
          <TextField
            style={{
              width: "100%",
            }}
            required
            label="Ingrese el Número de instalación"
            value={props.solicitudId}
            onChange={(e) => {
              props.setNumeroInstalacion(e.target.value);
            }}
          // onKeyPress={e => e.key === 'Enter' && props.onObtenerSolicitud()}
          // error={props.errorSolicitud ? true : false}
          // helperText={props.errorSolicitud} />
          />
          <TextField
            style={{
              width: "100%",
              marginBottom: 20,
            }}
            required
            label="Ingrese la clave"
            value={props.claveRevision}
            onChange={(e) => {
              props.setPassword(e.target.value);
            }}
            onKeyPress={(e) => e.key === "Enter" && props.onObtenerVehiculo()}
            error={props.errorSolicitud ? true : false}
            helperText={props.errorSolicitud}
          />
          {props.loading !== "customer" && (
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => props.onObtenerVehiculo()}
              disabled={props.loading !== "" ? true : false}
            >
              Acceder
            </Button>
          )}
          {props.loading === "customer" && (
            <div className={classes.linearProgress}>
              <LinearProgress />
            </div>
          )}
        </Container>
      )}
    </div>
  );
};
