import React from 'react';
import './styles.css';
const Main = ({ onAceptar, onClose, operacion }: IProps) => {
    return (
        <div className='modal-container'>
            <div className='modal-content'>
                <p>¿Estás seguro que deseas realizar el {operacion}?</p>
                <div className='footer'>
                    <button onClick={onClose}>CANCELAR</button>
                    <button id="btn-aceptar" onClick={onAceptar}>ACEPTAR</button>
                </div>
            </div>
        </div>
    )
}

export default Main;

interface IProps {
    operacion: string;
    onClose: () => void;
    onAceptar: () => void;
}